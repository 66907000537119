h2 {
  color: #43414e !important;
}

.top {
  background-image: url('../images/rakukan-header-image.png');
  background-size: cover;
  width: 100%;
  min-height: 500px;
  .bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.767);
  }
  // min-height: 500px;
  // .top-bg {
  //   background-size: cover;
  //   width: 60%;
  //   position: absolute;
  //   right: 0;
  //   img {
  //     object-fit: none;
  //     height: 500px;
  //     width: 100%;
  //   }
  // }
  // .text-section {
  //   position: absolute;
  //   padding-left: 50px;
  //   padding-top: 50px;
  //   padding-bottom: 50px;
  // }

  h1 {
    line-height: 3.9rem;
    width: 50%;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    width: 50%;
  }

  .btn,
  a.btn,
  button.btn {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    padding: 1rem 4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    letter-spacing: 0.1em;
    color: #212529;
    border-radius: 0.5rem;
  }

  .btn-wrap {
    margin: 30px 0;
  }

  a.btn-c {
    font-size: 1.3rem;

    position: relative;

    padding: 0.25rem 2rem 1.5rem 3.5rem;

    color: #fff;
    background: #ff9f31;
    -webkit-box-shadow: 0 5px 0 #ca8b42;
    box-shadow: 0 5px 0 #ca8b42;
  }

  a.btn-c span {
    font-size: 1rem;

    position: absolute;
    top: -10px;
    left: calc(50% - 150px);

    display: block;

    width: 300px;
    padding: 0.2rem 0;

    color: #ff9f31;
    border: 2px solid #ff9f31;
    border-radius: 0.5rem;
    background: #fff;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  }

  a.btn-c:hover {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);

    color: #fff;
    background: #ffb03a;
    -webkit-box-shadow: 0 2px 0 #ca8b42;
    box-shadow: 0 2px 0 #ca8b42;
  }

  a.btn-c:hover:before {
    left: 2rem;
  }

  @media screen and (max-width:1201px) {
    h1, p {
      width: 60%;
    }
  }

  @media screen and (max-width:991px) {
    h1, p {
      width: 80%;
    }
  }

    @media screen and (max-width:768px) {
      h1 {
        margin:auto;
        font-size: 2rem;
        line-height: 3.5rem;
        width: 90%;
      }

      p {
        margin:auto;
        width: 90%;
      }

      button.btn {
        font-size: 1.8rem;
      }

      a.btn-c {
        font-size: 1rem;
      }

      .btn-wrap {
        margin-top: 50px;
      }
    }

  @media screen and (max-width: 376px) {
    h1 {
      margin:auto;
      font-size: 1.5rem;
      line-height: 2.5rem;
      width: 100%;
    }

    p {
      margin:auto;
      width: 100%;
    }

    button.btn {
      font-size: 1.3rem;
    }
    a.btn-c {
      span {
        font-size: 0.9rem;
      }
      font-size: 0.8rem;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .btn-wrap {
      margin-top: 50px;
    }
  }
}

.features, .how-to-use {
  padding-top: 50px;
  h3 {
    font-size: 1.2rem;
  }

  .box-absolute {
    position: absolute;
    display: contents;
  }
  .card-absolute-body {
    position: absolute;
    top: 0;
    display: contents;
  }

  .card-number {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 4.5rem;
    color: rgb(255, 235, 205);
  }
}

.faq {
  .pointer-sec {
    cursor: pointer;
  }
  .body-text {
    white-space: pre-line;
  }
}

.text-orange {
  color: #ff9f31 !important;
}

.text-light-gray {
  color: #383b3d;
}

.border-orange {
  border: solid 1px #ff9f31 !important;
}